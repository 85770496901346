.modal-header {
    background-color: #eee;
    color: #3e3e3f;

    .fa-times {
        color: #d6d6d6;

        &:hover {
            color: #3e3e3f
        }
    }
}

.width-1000 {
	min-width: 1000px;
}
.popupModal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 300px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    border-radius: 8px;
}

.popupModal .alert-success {
    border-radius: 8px;
}

.popupModal .alert-danger {
    border-radius: 8px;
}

.modal-xl {
    max-width: 1000px;
}

.modal-xxl {
	max-width: 1200px;
}

.modal-larger {
    max-width: 800px;
}

@media only screen and (max-width: 810px) {
    .modal-larger {
        max-width: 95vw;
    }
}

.modal-lg {
    max-width: 600px;
}

.modal-width {
    width: 700px;
}

.high-opacity {
	opacity: .9 !important;
}
