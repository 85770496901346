.select-inverse {
	color: $primary !important;
	background-color: #fff !important;
	border-color: $primary !important;
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 4px;
}

	.navbar .dropdown-item {
		color: $primary-contrast;
		background-color: $primary;

		&:hover {
			color: $primary;
			background-color: $primary-contrast;
		}
	}

	.unstyled-navbar .dropdown-item {
		color: inherit;
		background-color: inherit;
		cursor: pointer;

		&:hover {
			color: $primary-contrast;
			background-color: $primary;
		}
	}
