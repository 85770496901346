
// Promo Banner

.alert-promo {
	background-color: #e69704;
	color: rgb(255, 255, 255);
	width: 100%;
	border-radius: 0%;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.promo-img {
	max-height: 65px;
	padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
}

.promo-text {
	margin-bottom: 0px;
}

.alert-promo-with-close-button {
	background-color: #FF7511;
	color: rgb(255, 255, 255);
	z-index: 1;
	border-radius: 0%;
	width: 100%;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.pt-27 {
	padding-top: 27px;
}

.background-color-white {
	background-color: #fff;
}

.font-color-black {
	color: black;
}
