.btn {
    border-radius: 5px;
    text-transform: none;
    font-size: 16px;

    &.round {
        border-radius: 25px;
    }

    &:not(:last-child) {
        margin-right: 25px;
    }
}

.btn-large, .btn-lg {
    font-size: 24px;
}

.btn-info {
    font-weight: bold;
}

.btn-info:hover {
    color: $primary;
    background-color: $primary-contrast;
}

.btn-primary {
    background-color: $primary;
    color: $primary-contrast;
}

.btn-primary-alt {
	color: $primary;
	background-color: $primary-contrast;
	border: 1px solid $primary;
}

.btn-outline-primary {
	background-color: $primary;
	color: $primary-contrast;
	border-color: $primary-contrast;

	&:hover {
		background-color: $primary-contrast;
		color: $primary;
		border-color: $primary;
	}
}

.btn-secondary {
    background-color: $secondary;
    color: $secondary-contrast;
	&:disabled {
		color: $secondary-contrast;
	}
}

.btn-secondary:hover {
	color: $secondary;
    background-color: $secondary-contrast;
	border: 1px solid $secondary;
}


.btn-secondary-alt {
	color: $secondary;
	background-color: $secondary-contrast;
	border: 1px solid $secondary;
}

.btn-secondary-alt:hover {
	background-color: $secondary;
	color: $secondary-contrast;

	&:disabled {
		color: $secondary-contrast;
	}
}

.btn-default {
    color: #3e3e3f;
    background-color: #fff;
    border-color: #3e3e3f;
}

.btn-cta {
    color: $cta-color-contrast;
    background-color: $cta-color;
    border-color: $cta-color;

    &:hover {
        color: $cta-color;
        background-color: $cta-color-contrast;
        border-color: $cta-color;
    }
}

.btn-advertising {
	background-color: #0093c9;
}

.btn-advertising-success {
	background-color: #32cf07;
}

.account-settings-btn-active {
    background-color: $primary;
}

.btn-success {
    color: #fff;
    background-color: $success;
    font-weight: bold;

    &:hover {
        color: $success;
        background-color: #fff;
        border-color: $success;
    }

    &:disabled {
        color: #fff;
    }
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    font-weight: 700;
}

.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
}

.navbar .btn-link {
	color: $primary-contrast;
	background-color: primary;

	&:hover, &:active, &:visited, &:focus {
		color: $primary-contrast;
		background-color: $primary;
	}
}

.btn-link-inline {
	display: inline;
	padding: 0;
	margin: 0;
	border: 0;
	line-height: inherit;
	vertical-align: inherit;
	color: $primary;
	text-decoration: underline;
	font-size: inherit;

	&:hover {
		color: $primary;
	}
}

.btn-body {
    background-color: $body-color;
    color: $primary-contrast;
}

.btn-body:hover {
	color: $body-color;
    background-color: $primary-contrast;
	border: 1px solid $primary;
}

.mapfire-buttons {
	.btn-check + .btn-primary {
		background-color: transparent;
		border-color: transparent;
		color: #000000;
	}

	.btn-check:checked + .btn-primary {
		background-color: $primary;
		border-color: $primary;
		color: $primary-contrast;
	}

	.btn-check + .btn-primary:hover {
		background-color: $primary;
		border-color: $primary;
		color: $primary-contrast;
	}
}

.invalid-form-btn {
	background-color: #b5b5b5 !important;
	border-color: #b5b5b5 !important;
}
