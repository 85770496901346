@use 'sass:color';

$primary-light: color.scale($primary, $lightness: 45%) !default;
$primary-lighter: color.scale($primary, $lightness: 90%) !default;
$primary-dark: color.scale($primary, $lightness: -25%) !default;
$primary-darker: color.scale($primary, $lightness: -50%) !default;
$secondary-light: color.scale($secondary, $lightness: 45%) !default;
$secondary-lighter: color.scale($secondary, $lightness: 90%) !default;
$secondary-dark: color.scale($secondary, $lightness: -25%) !default;
$secondary-darker: color.scale($secondary, $lightness: -50%) !default;

$primary-contrast: #ffffff !default;
$secondary-contrast: #ffffff !default;

$cta-color: $secondary !default;
$cta-color-light: $secondary-light !default;
$cta-color-lighter: $secondary-lighter !default;
$cta-color-dark: $secondary-dark !default;
$cta-color-darker: $secondary-darker !default;
$cta-color-contrast: #ffffff !default;

$success: $secondary !default;
$success-contrast: #ffffff !default;

$body-color: #282938 !default;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

$font-primary: "Poppins", sans-serif !default;
$font-secondary: "Poppins", sans-serif !default;

@import "./bootstrap/scss/bootstrap";

@import "./colors";
@import "./buttons";
@import "./bundles";
@import "./loader";
@import "./icons";
@import "./modals";
@import "./products";
@import "./selects";
@import "./promo";
@import "./shopping"; // Orders 2.0 Shopping Flow

hr.dashed {
	border: none; /* Turn off all other borders */
	border-top: 2px dashed $secondary; /* Assign the height, border-style and color */
	background-color: unset; /* Remove the currentColor inherited bg (breaks dotted, dashed) */
	height: auto; /* Set height to auto, to show fully */
}

h1 {
	font-size: 54px;
	font-weight: 600;
}

/* Stetch an image to fill its container width with proper scaling */
.img-fill {
	width: 100%;
	height: auto;
}

.slide-title {
	color: $primary;
}

.features-section {
	background-color: $primary;
}

.current-page {
	background-color: $primary;
	border-color: $primary;
	color: #ffffff;
}

.mobile-template-wrapper-selected {
	padding-top: 5px;
	height: 50px;
	border: 2px solid $primary;
}

.template-wrapper-selected {
	border: 3px solid $primary;
}

li.tab {
	color: $primary;
}

li.tab-active {
	background-color: $primary-lighter;
	color: $primary;
}

.progress-bar {
	background-color: $primary-light;
}

.design-icon {
	color: $primary-light;
}

.design-icon-mobile {
	color: $primary-light;
}

.text-black {
	color: black;
}

#topcontrol {
	bottom: 50px;
}

.product-price {
	color: $primary;
}

.primary-font {
	font-family: $font-primary;
}

.secondary-font {
	font-family: $font-secondary;
}

body {
	overflow-x: hidden;
	font-family: $font-primary;
	color: $body-color;
}

.color-primary {
	color: $primary;
}

.color-primary-light {
	color: $primary-light;
}

.color-primary-lighter {
	color: $primary-lighter;
}

.color-secondary {
	color: $secondary;
}

.background-color-secondary {
	background-color: $secondary;
}

.primary-background {
	background-color: $primary;
}

.footer-background {
	background-color: $primary;
}

.header-background {
	background-color: $primary;
}

.nav-button:hover {
	color: #fff;
	text-decoration: underline;
	text-decoration-color: $secondary;
	text-decoration-thickness: 4px;
	text-underline-offset: 3px;
}

.nav-button-color {
	color: #fff;
}

.dropdown-links {
	color: $primary;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
}

.dropdown-links-mobile {
	color: $primary;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
}

.dropdown-nav-items {
	font-size: 14px;

	a:hover {
		color: $primary;
		text-decoration: underline;
	}
}

.footer-subheader {
	font-size: 18px;
	color: $primary;
}

.mobile-body {
	overflow: hidden;
}

.ad-option-cell:hover {
	border-color: $primary;
}

.topNavSelected {
	border: 4px solid $primary !important;
}

.toggle-button i.active {
	color: $primary;
}
.toggle-button i.inactive {
	color: $primary-lighter;
}

.selection-pip {
	margin-top: 0.25rem;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	li {
		border: 1px solid #ddd;
		border-radius: 15px;
		margin-top: 5px;
		margin-right: 5px;
		background-color: #ffffff;
		padding: 5px;
		padding-left: 10px;
		text-decoration: none;
		font-size: 1em;
		color: black;
		display: inline-block;
		position: relative;
	}

	ul li button.close {
		padding-left: 15px;
		padding-right: 5px;
		color: #3b3b3b;
		opacity: 1;
	}

	button.close {
		-webkit-appearance: none;
		padding: 0;
		cursor: pointer;
		background: 0 0;
		border: 0;
	}
	.close {
		float: right;
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		filter: alpha(opacity=20);
		opacity: 0.2;
	}
}

@media (max-width: 1000px) {
	.mobile-modal {
		width: 93%;
	}
}

.custom-slider .ngx-slider .ngx-slider-selection {
	background: $primary;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
	background-color: $primary;
}
.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
	background: $primary;
}

.nav-row {
	color: $primary;
}
.top-nav-selector {
	color: $primary;
}
.navSelectorText {
	color: $primary;
}

.input-error {
	color: red;
}

.form-check-input-checked-color {
	color: $primary;
}

.cursor-pointer {
	cursor: pointer;
}

.padded-list-item {
	padding-top: 10px;
}

ul.list-unstyled.arrow-list-item {
	li:before {
		font-family: "Font Awesome\ 5 Pro";
		content: "\f0da";
		margin: 0 5px 0 0;
		color: $secondary;
		font-weight: 900;
	}

	li {
		padding-top: 10px;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.faq-section {
	a {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
		color: inherit;
	}

	.accordion-button:not(.collapsed) {
		color: inherit;
		background-color: inherit;
	}

	.accordion-button {
		font-size: 0.6em;
		font-weight: bold;
		padding: 40px 0;
		box-shadow: none;
	}

	.accordion-collapse {
		border-top: 0px;
	}

	.accordion-item {
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: 1px solid $gray-400;
	}

	.ul-secondary {
		text-decoration: underline;
		text-decoration-color: $secondary;
	}

}

.form-floating-group {
	input {
		border-bottom-right-radius:0;
		border-top-right-radius:0;
	}
}

.pricing-table {
	th {
		background-color: $primary;
	}
}


.custom-day.range,
.custom-day:hover {
	background-color: $primary !important;
	color: $primary-contrast !important;
}
.custom-day.highlighted {
	background-color: $primary !important;
}
.custom-day.faded {
	background-color: $primary-light !important;
}

@media (max-width: 1000px) {

	.yes-no-radio {
		width: 1em;
		height: 1em;
		margin-right: 0px;
	}
}

.slide-container {
	padding: 20px;
}
