$taradel-blue: #0630ce;
$taradel-green: #32cf07;

.color-primary {
	color: $primary;
}

.color-primary-darker {
	color: $primary-darker;
}

.color-secondary {
	color: $secondary;
}

.color-success {
    color: $success;
}

.background-color-primary {
	background-color: $primary;
	color: $primary-contrast;
}

.navbar.background-color-primary {
	.nav-link {
		color: $primary-contrast;
		&:hover, &:focus, &:active, &:visited {
			color: $primary-contrast;
		}
	}
}

.background-color-primary-lighter {
	background-color: $primary-lighter;
	color: $body-color;
}

.background-color-primary-light {
	background-color: $primary-light;
	color: $primary-contrast;
}

.border-color-primary {
	border-color: $primary;
}

.border-color-primary-lighter {
	border-color: $primary-lighter;
}

.border-color-secondary {
	border-color: $secondary;
}

.underline-primary-contrast-hover {
	border-bottom: 3px transparent solid;

	&:hover {
		border-bottom: 3px solid $primary-contrast;
	}
}

.color-primary-contrast {
	color: $primary-contrast;
}

.background-color-primary-contrast {
	background-color: $primary-contrast;
}

.color-lighter {
	color: $primary-lighter;
}

.underline-primary {
	text-decoration: underline;
	text-decoration-color: $primary;
}

.background-color-taradel-green {
	background-color: $taradel-green;
}

.border-bottom-primary {
	border-bottom-color: $primary !important;
}

.color-primary-light {
	color: $primary-light;
}
