

.bundle-cell, .bundle-cell-disabled {
	height: 100%;
	margin: 8px;
	padding: 1rem;
	border-top-width: 6px;
	border-top-style: solid;
	border-radius: 6px;
	background-color: white;
	box-shadow: 0px 4px 4px #00000020;
	font-weight: 600;
	transition: transform .2s ease-in-out;

	&.scale-effect:hover, &.scale-effect.selected-bundle {
		transform: scale(1.035);
	}

	&.selected-bundle {
		border-color: #8F3BD0;
		border-width: 6px 1px 1px 1px;
		box-shadow: 1px 1px 10px #8F3BD0;
	}

	.table > :not(caption) > * > * {
		padding: 0.25rem;
	}

	.table > :not(caption) > * > ul {
		padding-left: 2rem;
	}

	.table > tr {
		padding: 0.25rem;
	}

	&.tier-major-accent {
		.text-savings-color {
			color: #32cf07;
		}

		.breakdown-wrapper {
			border-radius: 6px;
			background-color: white;
		}

		.bundle-cell-corner {
			position: absolute;
			display: unset;
			top: 0px;
			right: 0px;
		}
	}

	.text-lowlight {
		font-weight: 500;
	}

	.text-savings-color {
		color: #32CF07;
	}

	.bundle-cell-corner {
		display: none;
	}

	.product-thumbnail {
		width: 32px;
		height: 32px;
	}

	i.fa.hidden {
		width: 0px;
		visibility: hidden;
	}

	.btn.btn-large.btn-xl {
		padding: 1.5rem;
		font-size: 2em;
	}
}

.fa.fa-check-circle {
	color: #32cf07;
}

.primary-color-border {
	box-shadow: 0px 0px 0px 1px #0630CE;
}


@keyframes highlightScale {
	0% {
		transform: scale(1.0);
	}

	100% {
		transform: scale(1.05);
	}
}
