.fa-info-circle {
	color: #ccc
}

.add-icon::before,
.add-icon::after {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.long-arrow-right::after {
	font-family: "Font Awesome 5 Pro";
	font-weight: 400;
	content: "\f178";
}
