/* Orders 2.0 */
.shopping {
	padding: 30px 0;

	.channel-button {
		border: 1px solid $primary-light;
		border-radius: 0;
		cursor: pointer;

		&.selected {
			background-color: $secondary-lighter;
		}

		&:hover:not(.selected) {
			border: 1px solid #B471F8;
			box-shadow: 0px 1px 5px #B471F8;
		}

		.channel-name {
			font-weight: bold;
			font-size: 14px;
		}

		.icon {
			display: inline-block;
			width: 65px;
			height: 65px;
			margin: 0 5px 0 15px;
			background: url('/assets/images/order/channel-icons/background.svg') no-repeat center;
			background-size: 100%;
			position: relative;

			img {
				position: absolute;
				max-height: 100%;
				max-width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				-webkit-transform: translate(-50%, -50%); /* Safari and Chrome */
				-moz-transform: translate(-50%, -50%); /* Firefox */
				-ms-transform: translate(-50%, -50%); /* IE 9 */
				-o-transform: translate(-50%, -50%); /* Opera */
			}
		}
	}

	.btn-background {
		background: white;
		border: 1px solid #AED5E3;

		label.btn-design {
			height: 100%;
			width: 100%;
			background-color: white;
			border: none;

			&:hover {
				cursor: pointer;
			}
		}

		&:hover {
			cursor: pointer;
		}

		&:focus {
			border-color: #B471F8;
			outline: 0;
			box-shadow: 0 0 0 0.25rem rgba(180, 113, 248, 0.25);
		}
	}

	.form-check-input {
		width: 1rem;
		height: 1rem;
		margin-right: 15px;

		&:checked {
			background-color: $secondary;
			border-color: $secondary;
		}
	}

	.form-control, .form-select {

		&:focus {
			border-color: #B471F8;
			outline: 0;
			box-shadow: 0 0 0 0.25rem rgba(180, 113, 248, 0.25);
		}
	}


	.cart-item {
		.icon {
			display: inline-block;
			width: 65px;
			height: 65px;
			background: lightgray;
			border-radius: .25em;
			margin: 0 5px 0 15px;
			background: url('/assets/images/order/channel-icons/background.svg') no-repeat center;
			background-size: 100%;
			position: relative;

			img {
				position: absolute;
				height: 80%;
				width: auto;
				max-height: 80%;
				max-width: 80%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				-webkit-transform: translate(-50%, -50%); /* Safari and Chrome */
				-moz-transform: translate(-50%, -50%); /* Firefox */
				-ms-transform: translate(-50%, -50%); /* IE 9 */
				-o-transform: translate(-50%, -50%); /* Opera */
			}
		}
	}

	.nav-border {
		.nav-link {
			background: transparent;
			border: 0;
			border-bottom: 2px solid transparent;
		}

		.nav-link.active {
			border-bottom: 2px solid $primary;
		}
	}

	.accordion-button {
		&:focus {
			border-color: #fff;
			box-shadow: none;
		}

		&:not(.collapsed) {
			color: inherit;
			background-color: #fff;
			box-shadow: none;
		}
	}

	#authShippingTemplate {
		.shipping-message {
			color: $primary;
			text-decoration: underline;
			font-weight: 600;
		}
	}
}

.shopping-modal {

	.modal-header {
		background: $primary;
		color: #FFFFFF;
	}

	.form-control, .form-select {

		&:focus {
			border-color: #B471F8;
			outline: 0;
			box-shadow: 0 0 0 0.25rem rgba(180, 113, 248, 0.25);
		}
	}
}

.btn-save {
	@include button-variant(
		$background: $secondary,
		$border: $secondary,
		$color: #fff,
		$hover-color: #fff,
		$hover-background: color.scale($secondary, $lightness: -3%),
		$disabled-background: color.scale($secondary, $alpha: -35%),
		$disabled-border: color.scale($secondary, $alpha: -35%),
		$disabled-color: #fff,
		$active-color: #fff
	);
	border-radius:0;
}

/*.btn-save {
	background-color: $secondary;
	border-color: $secondary;
	border-radius: 0;
	color: #fff;

	&:hover {
		color: #fff;
		background-color: color.scale($secondary, $lightness: -3%);
	}
}*/
.order-payment {

	& .selected {
		border: 1px solid #1660CF;
		background: rgba(22, 96, 207, .1);

		& .form-control, .form-select {
			background: rgba(22, 96, 207, 0);

			&:focus {
				border-color: #1660CF;
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgba(22, 96, 207, 0.25);
			}

			&.is-valid {
				border-color: #1660CF;
			}
		}
	}

	.form-check-input:checked {
		background-color: $primary;
		border-color: $primary;
	}
}
// This makes the phone inputs look right on Firefox
.customer-address {
	@-moz-document url-prefix() {
		.phone-class {
			label {
				padding-top: 2rem;
				font-size: 14px;
			}
		}
	}
}
